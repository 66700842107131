import _Resizable from "./build/Resizable";
import _ResizableBox from "./build/ResizableBox";
var exports = {};
exports = function () {
  throw new Error("Don't instantiate Resizable directly! Use require('react-resizable').Resizable");
};
exports.Resizable = _Resizable.default;
exports.ResizableBox = _ResizableBox.default;
export default exports;
export const Resizable = exports.Resizable,
  ResizableBox = exports.ResizableBox;